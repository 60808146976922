// Dependencies
import React, { useState, useEffect, useCallback, useContext, useRef } from "react";
import { useSpring, animated } from "react-spring";
import { Link } from "gatsby";
// import PropTypes from "prop-types";
// Hooks
import useWindowSize from "../hooks/useWindowSize";
// Components
import { StoreContext } from "../components/Store";

const SVG = ({ className, width, height, animatedStyle, children, debug }) => {

  const aspectRatio = width / height;
  const calcWidth = aspectRatio * animatedStyle.height;
  const calcHeight = animatedStyle.height;

  const style = useSpring({
    width: calcWidth,
    height: calcHeight
  })

  return (
    <animated.svg className={className} width={style.width} height={style.height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
      {children}
    </animated.svg>
  )
}

const E1 = ({ animatedStyle }) => (
  <SVG className="e1" width={58} height={323} animatedStyle={animatedStyle} debug>
    <path d="M28.7676 29.174V132.458H45.9816V160.544H28.7676V293.726H50.9646V320H0.681641V2.90002H50.9646V29.174H28.7676Z" fill="#FA4E33" />
  </SVG>
)
const X2 = ({ animatedStyle }) => (
  <SVG className="x2" width={78} height={323} animatedStyle={animatedStyle}>
    <path d="M12.8718 152.39L0.86731 2.90002H27.1413L32.1243 80.363L35.9748 139.706H36.2013L40.0518 80.363L45.0348 2.90002H71.3088L59.0778 150.012L71.7618 320H45.4878L39.1458 210.827L36.2013 165.301H35.9748L33.0303 210.827L26.6883 320H0.414307L12.8718 152.39Z" fill="#FA4E33" />
  </SVG>
)
const C3 = ({ animatedStyle }) => (
  <SVG className="c3" width={83} height={323} animatedStyle={animatedStyle}>
    <path d="M34.8185 322.718C23.6445 322.718 15.0375 319.623 8.99753 313.432C3.03303 307.241 0.0507812 298.407 0.0507812 286.931V35.969C0.0507812 24.493 3.03303 15.6595 8.99753 9.46851C15.0375 3.27751 23.6445 0.182007 34.8185 0.182007C45.9925 0.182007 54.5618 3.27751 60.5263 9.46851C66.5663 15.6595 69.5863 24.493 69.5863 35.969V101.654H41.5003V35.969C41.5003 29.627 39.273 26.456 34.8185 26.456C30.364 26.456 28.1368 29.627 28.1368 35.969V286.931C28.1368 293.273 30.364 296.444 34.8185 296.444C39.273 296.444 41.5003 293.273 41.5003 286.931V188.63H69.5863V286.931C69.5863 298.407 66.5663 307.241 60.5263 313.432C54.5618 319.623 45.9925 322.718 34.8185 322.718Z" fill="#FA4E33"/>
  </SVG>
)
const E4 = ({ animatedStyle }) => (
  <SVG className="e4" width={59} height={323} animatedStyle={animatedStyle}>
    <path d="M28.8624 29.174V132.458H46.0764V160.544H28.8624V293.726H51.0594V320H0.776367V2.90002H51.0594V29.174H28.8624Z" fill="#FA4E33"/>
  </SVG>
)
const L5 = ({ animatedStyle }) => (
  <SVG className="l5" width={51} height={323} animatedStyle={animatedStyle}>
    <path d="M0.613281 2.90002H28.6993V293.726H45.4603V320H0.613281V2.90002Z" fill="#FA4E33"/>
  </SVG>
)
const S6 = ({ animatedStyle }) => (
  <SVG className="s6" width={82} height={323} animatedStyle={animatedStyle}>
    <path d="M35.4516 322.718C24.2776 322.718 15.6706 319.623 9.63058 313.432C3.66608 307.241 0.683838 298.407 0.683838 286.931V220.567H28.7698V286.931C28.7698 293.273 30.9971 296.444 35.4516 296.444C39.9061 296.444 42.1333 293.273 42.1333 286.931V248.2C42.1333 239.14 41.4916 230.57 40.2081 222.492C38.9246 214.338 36.8861 205.844 34.0926 197.01L9.63058 119.548C6.53508 109.582 4.27007 99.9175 2.83557 90.5555C1.40107 81.1935 0.683838 71.0765 0.683838 60.2045V35.969C0.683838 24.493 3.66608 15.6595 9.63058 9.46851C15.6706 3.27751 24.2776 0.182007 35.4516 0.182007C46.6256 0.182007 55.1948 3.27751 61.1593 9.46851C67.1993 15.6595 70.2193 24.493 70.2193 35.969V86.4785H42.1333V35.969C42.1333 29.627 39.9061 26.456 35.4516 26.456C30.9971 26.456 28.7698 29.627 28.7698 35.969V60.2045C28.7698 68.6605 29.4116 76.9278 30.6951 85.0063C32.0541 93.0093 34.1681 101.654 37.0371 110.941L61.2726 188.404C64.4436 198.445 66.7086 208.222 68.0676 217.735C69.5021 227.173 70.2193 237.328 70.2193 248.2V286.931C70.2193 298.407 67.1993 307.241 61.1593 313.432C55.1948 319.623 46.6256 322.718 35.4516 322.718Z" fill="#FA4E33"/>
  </SVG>
)
const I7 = ({ animatedStyle }) => (
  <SVG className="i7" width={42} height={323} animatedStyle={animatedStyle}>
    <path d="M0.443359 2.90002H28.5294V320H0.443359V2.90002Z" fill="#FA4E33"/>
  </SVG>
)
const O8 = ({ animatedStyle }) => (
  <SVG className="o8" width={83} height={323} animatedStyle={animatedStyle}>
    <path d="M34.7951 322.718C23.6211 322.718 15.0141 319.623 8.97409 313.432C3.00959 307.241 0.0273438 298.407 0.0273438 286.931V35.969C0.0273438 24.493 3.00959 15.6595 8.97409 9.46851C15.0141 3.27751 23.6211 0.182007 34.7951 0.182007C45.9691 0.182007 54.5383 3.27751 60.5028 9.46851C66.5428 15.6595 69.5628 24.493 69.5628 35.969V286.931C69.5628 298.407 66.5428 307.241 60.5028 313.432C54.5383 319.623 45.9691 322.718 34.7951 322.718ZM34.7951 296.444C39.2496 296.444 41.4768 293.273 41.4768 286.931V35.969C41.4768 29.627 39.2496 26.456 34.7951 26.456C30.3406 26.456 28.1133 29.627 28.1133 35.969V286.931C28.1133 293.273 30.3406 296.444 34.7951 296.444Z" fill="#FA4E33"/>
  </SVG>
)
const R9 = ({ animatedStyle }) => (
  <SVG className="r9" width={76} height={323} animatedStyle={animatedStyle}>
    <path d="M70.2884 288.29C70.2884 296.595 70.6659 302.862 71.4209 307.09C72.1759 311.242 73.6104 315.546 75.7244 320H45.8264C44.3164 316.98 43.3349 313.167 42.8819 308.562C42.4289 303.956 42.2024 297.199 42.2024 288.29V183.421C42.2024 180.476 41.5984 178.4 40.3904 177.192C39.2579 175.908 37.4459 175.267 34.9544 175.267H28.8389V320H0.75293V2.90002H35.4074C43.9389 2.90002 50.7339 4.18353 55.7924 6.75054C60.9264 9.24204 64.6259 13.0548 66.8909 18.1888C69.1559 23.3228 70.2884 30.0045 70.2884 38.234V136.195C70.2884 143.594 69.2314 149.295 67.1174 153.296C65.0034 157.298 61.4171 160.053 56.3586 161.563C61.4171 162.922 65.0034 165.565 67.1174 169.491C69.2314 173.341 70.2884 178.853 70.2884 186.025V288.29ZM42.2024 37.328C42.2024 34.3835 41.5984 32.3073 40.3904 31.0993C39.2579 29.8158 37.4459 29.174 34.9544 29.174H28.8389V148.087H34.9544C37.4459 148.087 39.2579 147.483 40.3904 146.275C41.5984 144.991 42.2024 142.877 42.2024 139.933V37.328Z" fill="#FA4E33"/>
  </SVG>
)

const Letter = ({ animatedStyle, sectionsLength, activeSection, startAt = 1, children }) => {

  // UX
  const ref = useRef();
  const { width, height } = useWindowSize();
  const [refHeight, setHeight] = useState(null);
  const setRef = useCallback(node => {
    if (node) {
      ref.current = node;
      setHeight(
        node.getBoundingClientRect().height
      )
    }
  }, [activeSection]);

  const offset = startAt;
  const index = activeSection >= offset ? activeSection - offset : 0;
  const length = sectionsLength - offset;

  // CSS
  const translateY = (((height - 24) - refHeight) / length) * index;

  const style = useSpring({
    transform: `translateY(${translateY}px)`
  });

  return (
    <animated.div className="logo__letter" ref={setRef} style={style}>
      {children}
    </animated.div>
  )
}

const Logo = ({ fill = "#EA543E", sectionsLength }) => {

  // UX
  const [activeSection] = useContext(StoreContext);
  const isLast = sectionsLength === activeSection;

  // CSS
  const { width, height } = useWindowSize();
  function calc(vw) {
    return vw ? width * (vw / 100) : 0
  };

  const logoWidth = width < height ? 100 : 50;
  const logoShrinkWidth = width < height ? 33 : 15;
  const logoAspectRatio = 0.51;

  const animatedStyle = {
    width: activeSection > 0 ? calc(logoShrinkWidth) : calc(logoWidth),
    height: activeSection > 0 ? calc(logoShrinkWidth * logoAspectRatio) : calc(logoWidth * logoAspectRatio)
  };

  // Props
  const sectionProps = { sectionsLength, activeSection };

  return (
    <Link to={'/'} className={`logo ${isLast ? 'end' : ''}`}>
      <div className="logo__wrapper" style={animatedStyle}>
        <Letter startAt={3} {...sectionProps}><E1 animatedStyle={animatedStyle} /></Letter>
        <Letter startAt={3} {...sectionProps}><X2 animatedStyle={animatedStyle} /></Letter>
        <Letter startAt={2} {...sectionProps}><C3 animatedStyle={animatedStyle} /></Letter>
        <Letter startAt={2} {...sectionProps}><E4 animatedStyle={animatedStyle} /></Letter>
        <Letter startAt={1} {...sectionProps}><L5 animatedStyle={animatedStyle} /></Letter>
        <Letter startAt={1} {...sectionProps}><S6 animatedStyle={animatedStyle} /></Letter>
        <Letter startAt={1} {...sectionProps}><I7 animatedStyle={animatedStyle} /></Letter>
        <Letter startAt={2} {...sectionProps}><O8 animatedStyle={animatedStyle} /></Letter>
        <Letter startAt={3} {...sectionProps}><R9 animatedStyle={animatedStyle} /></Letter>
      </div>
    </Link>
  )
}

export default Logo
