import { graphql, useStaticQuery } from "gatsby";

export default function () {
  const data = useStaticQuery(graphql`
    {
      allContentfulSettings {
        nodes {
          defaultTitle
          defaultDescription
          defaultShareImage {
            fluid(maxHeight: 640) {
              ...GatsbyContentfulFluid_noBase64
            }
          }
          tagline {
            childMarkdownRemark {
              html
            }
          }
          contact {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  `)
  return data.allContentfulSettings.nodes[0]
}
