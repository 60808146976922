// Dependencies
import React from "react"
// Components
import Header from "../components/Header"
import Logo from "../components/Logo"
import Meta from "../components/Meta"
import Store from "../components/Store"
// CSS
import "../css/styles.scss"

const Layout = ({ title, sectionsLength, children }) => (
  <div id="layout">
    <Meta title={title} />
    <Logo sectionsLength={sectionsLength} />
    <main>{children}</main>
  </div>
)

export default Layout
