// Dependencies
import React, { useState } from "react";

export const StoreContext = React.createContext();
export const StoreContextActions = React.createContext();

const Store = ({ children }) => {

  const [activeSection, setActiveSection] = useState(0);

  return (
    <StoreContext.Provider value={[activeSection]}>
      <StoreContextActions.Provider value={[setActiveSection]}>
        {children}
      </StoreContextActions.Provider>
    </StoreContext.Provider>
  )
}

export default Store
